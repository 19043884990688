<template lang="pug">
div.projects_container
  v-dialog
  div.view_subtitle Your Videos
  div#my_projects
    div.create_project_button(v-on:click="create")
      img.add_icon(:src="addIconSrc")
      span Create new video
    input#input_field_to_copy(type="text")
    div.loading(v-show="loading")
      img(v-bind:src="loadingSrc")
    div.project_container(v-for="(project, index) in projects" v-bind:key="project.id" v-bind:class="{small: smallOrSingle}")
      div.wrapper
        div.completed(v-show="project.completed") video sent!
        div.project_info_container
          a.project_title(v-on:click="goUpdateProject(index)") {{ project.title ? project.title : "project "+(index+1) }}
          div.project_progress ({{ project.numCompleted }}/{{ project.numInvited }}) &nbsp;&nbsp; people added videos
          div.deadline
            span email will be sent {{ formatDeadline(project.deadline) }} &nbsp;&nbsp;
          div.delete_project
            a(v-on:click="confirmDelete(project.id, project.title)") remove
        .project_buttons_small
          img(:src="recIconSrc" style="touch-action: manipulation" title="Record your video" v-on:click="goAddVideo(index)")
          img(v-if="project.numCompleted > 0" v-on:click="play(project.slug)" :src="playIconSrc" style="touch-action: manipulation" title="play videos")
          img(v-on:click="copyShareUrl(project.shareUrl)" :src="shareButtonSrc" style="touch-action: manipulation" title="Share this link on social media to allow anyone to add a video")
        div.clear
        div.background_overlay
        img.background(v-if="project.thumbnailUrl" :src="project.thumbnailUrl")
</template>

<script>

import { GET_PROJECTS_QUERY } from '../graphql/queries'
import { DELETE_PROJECT_MUTATION } from '../graphql/mutations'
import { clearSelection, formatDate, missingUserInfo } from '../lib/utils'

export default {
  name: 'Projects',
  props: {
    isSmallScreen: {
      type: Boolean,
      default: false
    }
  },
  components: {

  },
  computed: {
    smallOrSingle () {
      return this.isSmallScreen || this.projects.length == 1
    },
    recIconSrc () {
      return process.env.BASE_URL + "video_record_button.svg"
    },
    loadingSrc () {
      return process.env.BASE_URL + "loading_dark.gif"
    },
    shareButtonSrc () {
      return process.env.BASE_URL + "share_button.svg"
    },
    addIconSrc () {
      return process.env.BASE_URL + "plus_icon_white.svg"
    },
    currentUser () {
      return this.$store.state.user.user
    },
    isSignedIn () {
      return this.$store.getters.signedIn
    },
    playIconSrc () {
      return process.env.BASE_URL + "play_project_button_icon.svg"
    },
  },
  watch: {
  },
  data () {
    return {
      projects: [],
      loading: false,
    }
  },
  apollo: {

  },
  methods: {
    confirmDelete: function(projectId, title) {
      this.$modal.show('dialog', {
        title: 'Remove this project?',
        text:title,
        buttons: [
          {
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Yes, remove',
            handler: () => {
              this.deleteProject(projectId)
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },
    deleteProject: function(projectId) {
      const vue = this
      this.$apollo.mutate({
        mutation: DELETE_PROJECT_MUTATION,
        variables: {
          projectId: projectId,
        }
      }).then((data) => {
        console.log(data)
        const success = data.data.deleteProject.success
        const errors = data.data.deleteProject.errors
        if (success) {
          vue.getProjects()
        } else {
          this.errorsToast(errors)
        }
      }).catch((error) => {
        console.error(error)
        this.errorToast(error)
      })
    },
    formatDeadline: function(deadline) {
      return formatDate(deadline)
    },
    goAddVideo: function(project_index) {
      const project = this.projects[project_index]
      this.$store.commit('setProject', project)
      this.$router.push({
        name:"AddVideoContainer",
        params: {
          next: "/invite"
        }
      })
    },
    copyShareUrl: function(url) {
      var copyText = document.getElementById("input_field_to_copy");

      copyText.value = url

      /* Select the text field */
      copyText.select()
      copyText.setSelectionRange(0, 99999) /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy")
      clearSelection()
      this.$toast.success("copied "+url+" to clipboard")
    },
    getProjects: function () {
      this.loading = true
      this.$apollo.query({
        query: GET_PROJECTS_QUERY,
        fetchPolicy: 'network-only',
      }).then((data) => {
        this.loading = false
        this.projects = data.data.projects

        // !FIXME !HACK: this competes with the updateInfo redirect so hacking this with only routing if have all info
        if (this.projects.length <= 0 && !missingUserInfo(this.currentUser)) this.$router.push("/create")

        const errors = data.errors
        this.errorsToast(errors)
      }).catch((error) => {
        console.error(error)
        this.loading = false
        this.errorToast(error)

        if (error && /you are not signed in/i.test(error)) {
          this.$store.dispatch('signOut')
          this.$router.push({name:"SignOut"})
        }
      })
    },
    create: function() {
      this.$store.commit('clearProject')
      this.$router.push({name:"CreateOrUpdateProject"})
    },
    play: function(slug) {
      this.$router.push(
        {name:"PlayProject",
        params: {
          slug: slug
        }
      })
    },
    goUpdateProject: function(project_index) {
      const project = this.projects[project_index]
      this.$store.commit('setProject', project)
      this.$router.push({name:"UpdateProject"})
    },
  },
  mounted: function () {
    if (this.isSignedIn) { this.getProjects() }
    // window.vue = this
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.project_container {
  margin: 0.5% 0.25% 0px 0.25%;
  background-color: #332554;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;

  overflow: hidden;
  width: 49.5%;
  float:left;
}
.project_container.small {
  width: 100%;
  float: none;
}

.project_container  img.background {
  display: block;
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.project_container .background_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: RGBA(0, 0, 0, .40);
}

#input_field_to_copy {
  position:absolute;
  z-index: 1;
  top: -100px;
  left: 0px;
  opacity: 0;
}

.create_project_button {
   background-color: RGBA(63, 39, 218, 1.00);
   color: #ffffff;
   font-family: gibsonsemibold;
   font-size: 24px;
   padding: 38px 38px;
   cursor: pointer;

   border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;

  margin-bottom: 24px;
}
.create_project_button:hover {
  background-color: #4900FE;
}

.add_icon {
  cursor:pointer;
  display: block;
  float:right;
}

.project_info_container {
  position: relative;
  z-index: 100;
  display: block;
  color: #9399AB;
  float: none;
  width: auto;
  overflow: hidden;
  text-align: left;
}

.wrapper {
  position: relative;
  padding: 38px 38px;
}

a.project_title {
  display: block;
  font-family: gibsonsemibold;
  font-size: 24px;
  margin-bottom: 6px;

  display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
   -webkit-box-orient: vertical;
   overflow: hidden;
}
.project_buttons {
  position: relative;
  display: block;
  float: right;
  z-index: 100;
}

.project_buttons_small {
  position: relative;
  z-index: 100;
  display: block;
  margin-top: 22px;
  text-align: left;
}

.project_buttons_small img {
  cursor: pointer;
  margin-right: 12px;
}

.project_buttons img {
  cursor: pointer;
  margin-left: 12px;
}

.project_container img:hover {
  opacity: 0.8;
}

.deadline {
  color: #9399AB;
  margin-top: 6px;
}

.completed {
  position: absolute;
  top: 10px;
  left: 38px;
  z-index: 500;
  display: block;
  background-color: #4900FE;
  opacity: 0.9;
  color: #ffffff;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.delete_project {
  margin-top: 4px;
}

.delete_project a {
  color: #a5aab6;
}
</style>
